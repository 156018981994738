
import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import BackButton from '@/views/module/common/BackButton.vue';

import User from '@/app/com/main/module/business/user/bean/User';
import ContactRelation from '@/app/com/main/module/business/contact/bean/ContactRelation';
import app from '@/app/App';
import UserBox from '@/app/com/main/module/business/user/box/UserBox';
import ContactRelationBox from '@/app/com/main/module/business/contact/box/ContactRelationBox';
import ContactRelationController from '@/app/com/main/module/business/contact/controller/ContactRelationController';
import DataBackAction from '@/app/base/net/DataBackAction';
import contactInfoViewModel from '@/platform/vue/view/model/ContactInfoViewModel';
import CommonIcon from '@/platform/common/web/CommonIcon';

import Prompt from '@/platform/wap/common/Prompt';
import UserChatViewController from '@/app/com/main/module/business/chat/controller/UserChatViewController';


@Component({
    components: {
        BackButton,
    },
})
export default class ContactInfoViewPane extends Vue {
    private infoBg: string = 'assets/general/wap/images/info/bg1.jpg';
    private noLogo = CommonIcon.noLogo;
    private model = contactInfoViewModel;

    private show: boolean = false;
    private user: User = new User();
    private relation: ContactRelation = new ContactRelation();
    private remarkEdit: boolean = false;
    private showInfo: boolean = false;

    private openDialog: boolean = false;
    private remark: string = '';

    @Prop({
        type: String,
        required: false,
        default: () => (''),
    })
    private userId!: string;

    public mounted() {
        this.setUserId(this.userId);
    }

    public setUserId(userId: string) {
        const userBox: UserBox = app.appContext.getMaterial(UserBox);
        const contactListBox: ContactRelationBox = app.appContext.getMaterial(ContactRelationBox);
        const user: User = userBox.getUser(userId);
        let relation: ContactRelation = new ContactRelation();
        const list = contactListBox.getContactInContactRelationListByUserId(userId);
        if (list && list.length > 0) {
            relation = list[0];
        }
        this.setUser(user, relation);
    }

    public setUser(user: User, relation: ContactRelation) {
        this.model.setUser(user);
        this.model.setRelation(relation);
        // console.log('用户信息', this.model)
    }

    private openSend() {
        const userId = this.userId;
        this.onOpenSend(userId);
    }

    // @Emit('on-to-send')
    private onOpenSend(key: string) {
        // 发送消息
        // this.$router.push({path: '/chat.user'});
        const controller: UserChatViewController = app.appContext.getMaterial(UserChatViewController);
        controller.showUserChatById(key);
    }

    private openRemark(): void {
        const own = this;
        const oldRemark = (this.relation) ? this.relation.remark : '';
        this.remark = oldRemark;
        this.openDialog = true;
    }

    private updateRemark(): void {
        const own = this;
        const contactUserId = this.userId;
        const oldRemark = (this.relation) ? this.relation.remark : '';
        let text = this.remark;

        const back: DataBackAction = {
            back(data: any): void {
                if (data) {
                    const info = data.info;
                    if (info) {
                        if (info.success) {
                            own.relation.remark = text;
                            own.openDialog = false;
                        }
                    }
                }
            },
            lost(data: any): void {
                Prompt.notice('请求失败！');
            },
            timeOut(data: any): void {
                Prompt.notice('请求超时！');
            },
        } as DataBackAction;
        const ccc: ContactRelationController = app.appContext.getMaterial(ContactRelationController);
        if (!text) {
            text = '';
        }
        ccc.updateRemark(contactUserId, text, back);
    }
}
